<template>
  <div class="splash-screen-wrap fill-height">
    <landing-page-header />
    <div class="orange--bg"></div>
    <div class="splash--message__wrap d-flex justify-center mx-3">
      <v-card max-width="537" class="pa-sm-16 pa-8" max-height="358">
        <div>
          <h4>Your Campaign is Live! 🔥</h4>
          <span>Share link to your friends or customers to help raise money through this campaign.</span>
         <div class="mt-16">
           <v-btn color="#00050D" @click="copyLink($route.query.code)" rounded depressed class="white--text mb-3" block>Copy Link</v-btn>
           <v-btn rounded depressed block :to="{name: 'CampaignDetails', params: {id: $route.query.id}}">View Campaign</v-btn>
         </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import LandingPageHeader from "@/components/LandingPageHeader";
import CampaignLinkGenerator from "@/mixins/CampaignLinkGenerator";
export default {
  name: "SplashScreen",
  components: { LandingPageHeader },
  mixins: [CampaignLinkGenerator]
};
</script>

<style scoped lang="scss">
::v-deep {
  .orange--bg {
    background-size: cover !important;
    background: url('../../../assets/orange-bg.svg') no-repeat;
    min-height: 237px;
    width: 100vw;

    @media (max-width: 767px) {
      height: 55vh;
    }
  }

  .splash--message__wrap {
    height: 40vh;
    align-items: center;

    @media (min-width: 767px) {
      margin-top: -138px;
      height: auto;
    }
  }

  h4 {
    font-weight: 700;
    font-size: 32px;
    line-height: 32px;
    /* identical to box height, or 100% */

    letter-spacing: -0.04em;

    /* Brown/500 */

    color: #514A41;

    &+span {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;

      color: #808080;
    }
  }

  .v-card {
    background: #FFFFFF;
    /* Grey/200 */

    border: 1px solid #F4F3F3 !important;
    box-shadow: 0px 12px 24px rgba(173, 173, 173, 0.08), 0px 2px 4px rgba(173, 173, 173, 0.04) !important;
    border-radius: 24px !important;
  }
}
</style>
